import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IssuesList from "./IssuesList";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<IssuesList />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
